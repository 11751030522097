
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { getLocaleKeyFromValue } from 'helpers/getLocaleKey';
import { getTranslations } from 'helpers/translations';
import { ISR_REVALIDATE_TIME } from 'lib/constants';
import { GetStaticPaths, GetStaticProps, GetStaticPropsResult } from 'next';
import ContentView, { _getDynamicPage, ContentViewProps } from 'pages/[...slug]';
import React from 'react';
import safeJsonStringify from 'safe-json-stringify';
import slugify from 'slugify';
import { getSubpageComponent, getSubpageProps } from 'utils/getOverviewPage';
type OverviewPageProps = {
    subpageType: string;
    pageProps: any;
};
export default function OverviewPage({ subpageType, pageProps, model, pageBodyLinks, }: OverviewPageProps & ContentViewProps): JSX.Element {
    if (model)
        return <ContentView model={model} pageBodyLinks={pageBodyLinks}/>;
    const OverviewPageComponent = getSubpageComponent(subpageType);
    return <>{OverviewPageComponent && React.createElement(OverviewPageComponent as any, { ...pageProps })}</>;
}
export const getStaticPaths: GetStaticPaths = async ({ locales }) => {
    const mainSubpage = ['project', 'insight', 'news', 'product', 'career', 'contactUsUrl'];
    const paths = await Promise.all(mainSubpage.flatMap((category) => locales?.map(async (locale) => {
        const { multiple } = await getTranslations(locale);
        return {
            params: { mainSubpage: slugify(multiple(category), { lower: true }) },
            locale,
        };
    }) ?? []));
    return {
        paths,
        fallback: 'blocking',
    };
};
const getStaticProps: GetStaticProps<OverviewPageProps | ContentViewProps> = async (ctx): Promise<GetStaticPropsResult<OverviewPageProps | ContentViewProps>> => {
    const languageCodename = ctx.locale ?? process.env.NEXT_PUBLIC_DEFAULT_LOCALE!;
    const subpageType = await getLocaleKeyFromValue(languageCodename, 'multiple', ctx.params!.mainSubpage!);
    const pageProps = await getSubpageProps(subpageType[0], languageCodename);
    if (!pageProps) {
        return await _getDynamicPage(ctx);
    }
    return {
        props: {
            subpageType: subpageType[0],
            pageProps: JSON.parse(safeJsonStringify(pageProps)),
        },
        revalidate: ISR_REVALIDATE_TIME,
    };
};

    async function __Next_Translate__getStaticProps__195f55aa5e1__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/[mainSubpage]/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__195f55aa5e1__ as getStaticProps }
  